import React from "react";
import { Link } from "react-router-dom";
import "./Team.css";

const teamMembers = [
    {
        name: "Ganesh Manjeshwara Padakannaya",
        title: "Chief Executive Officer",
        description: "Ganesh is a distinguished Chartered Accountant (ICAI) and Company Secretary (ICSI) with an impressive track record of excellence in finance, accounting, and business advisory services. Holding a Bachelor’s degree in Business Management and a Law degree, he brings over two decades of professional experience. Ganesh has served in pivotal roles, including International Finance Manager and senior leadership positions at esteemed organizations such as Religare and Boston Financials, demonstrating a profound ability to drive financial strategy and operational success.",
        image: "/images/ganesh.jpg",
    },
    /* {
        name: "April Van Horn",
        title: "Vice President",
        description: "April is a dynamic and results-driven accounting professional with extensive expertise in cash flow forecasting and complex reconciliations. She brings a strong background in managing accounts receivable and payable, while ensuring seamless interdepartmental coordination. April’s experience includes implementing advanced software systems and enhancing internal controls to drive operational efficiency. She has held key positions at MON-YOUGH Community Services, Management Science Associates, and leading manufacturing industries, consistently delivering excellence in financial management and process optimization.",
        image: "/images/april.jpg",
    }, */
    {
        name: "Kawshal G",
        title: "Vice President Corporate Compliance",
        description: "Kawshal is an accomplished corporate compliance professional and an associate member of ICSI, with a Bachelor's degree in Accounting. He brings extensive expertise in corporate governance across the US and India, specializing in company incorporations, legal documentation, and statutory compliance. Kawshal has held key positions at Ullas Kumar Associates and Boston Financials, where he played a pivotal role in ensuring regulatory adherence and facilitating seamless corporate operations. His meticulous approach and in-depth knowledge make him a valuable asset in navigating complex compliance frameworks.",
        image: "/images/kawshal.jpg",
    },
    /* {
        name: "Sanjeev Sunkad",
        title: "Senior Manager Operations",
        description: "Sanjeev is a semi-qualified Chartered Accountant with over 10 years of experience in Accounts, Finance, and Audit functions. Holding a Bachelor’s degree in Business Administration (BBA) from Karnataka University, he specializes in Accounting Advisory, Indirect Taxes, Statutory Audits, and Tax Compliance. Sanjeev is recognized for his expertise in financial accounting, payroll preparation, financial analysis, and risk management, alongside strong leadership and communication skills. His professional achievements include receiving client awards for financial reporting, enhancing team efficiency by streamlining processes, and successfully managing accounting teams during new branch setups. Sanjeev has held roles in In2talk Technologies, Chhabra Group, Consero Solutions, and Rajagopal & Badri Narayanan.",
        image: "/images/sanjeev.jpg",
    }, */
];

const advisoryMembers = [
    {
        name: "Krishna Padakannaya",
        description: "Krishna is a highly experienced finance and management professional, specializing in outsourcing, financial due diligence, and mergers & acquisitions (M&A). A Chartered Accountant (ICAI) with a Bachelor's degree in Business Management and a Law degree, Krishna has a proven track record of leading finance operations across APAC, US, and EMEA regions. He has held senior leadership positions at renowned organizations, including Price Waterhouse, Emmvee, MetricStream, and Concentrix, consistently driving strategic financial initiatives and delivering exceptional results.",
        image: "/images/krishna.jpg",
    },
    {
        name: "M. Ranjith",
        description: "M. Ranjith is an accomplished finance professional and a Fellow Member of the Institute of Chartered Accountants of India (ICAI), with over 15 years of post-qualification experience. He has also earned the prestigious Certified Public Accountant (CPA) designation by successfully completing the examination conducted by the American Institute of Certified Public Accountants (AICPA, USA).A commerce graduate from Madras University, Mr. Ranjith possesses in-depth expertise across diverse financial domains, including Audit and Assurance, Income Tax Advisory and Litigation, Risk Advisory, and Strategic Consulting. His career is distinguished by a commitment to excellence and strategic leadership, enabling him to navigate complex financial challenges and deliver impactful solutions.Mr. Ranjith’s global perspective and proven expertise position him as a valuable contributor to the governance and strategic direction of NexusGLobe, where he is poised to drive innovation and uphold the organization’s commitment to excellence in financial management and oversight.",
        image: "/images/Ranjith.jpg",
    }, 
];

function Team() {
    return (
        <div className="team-page">
            <div className="main-header">
                <h1 className="team-title-main">Our Team</h1>
                <p className="team-subtitle">
                    Meet the professionals who are dedicated to managing your finances with excellence.
                </p>
            </div>            
            {/* Advisory Board Section */}
            <div className="outer-block">
                <h1 className="outer-block-title">Advisory Board</h1>
                <div className="team-container">
                    {advisoryMembers.map((member, index) => (
                        <Link to={`/advisory/${index}`} key={index} className="team-card">
                            <img src={member.image} alt={member.name} className="team-image" />
                            <h2 className="team-name">{member.name}</h2>
                        </Link>
                    ))}
                </div>
            </div>
            {/* Executive Board Section */}
            <div className="outer-block">
                <h1 className="outer-block-title">Executive Board</h1>
                <div className="team-container">
                    {teamMembers.map((member, index) => (
                        <Link to={`/team/${index}`} key={index} className="team-card">
                            <img src={member.image} alt={member.name} className="team-image" />
                            <h2 className="team-name">{member.name}</h2>
                            ` {member.title && <h3 className="team-title">{member.title}</h3>}`
                        </Link>
                    ))}
                </div>
            </div>
            
        </div>
    );
}

export default Team;
